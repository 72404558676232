
import { IAttachment, IClinic } from '@/modules/system/types';
import $app from '@/plugins/modules';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { Admin } from '@/modules/admin'

@Component
export default class ClinicDialog extends Vue {
  @Prop({ default: () => false })
  readonly value: boolean | undefined;

  @Prop({ default: () => false })
  readonly processing: boolean | undefined;

  @Prop({ default: () => null })
  readonly clinic: IClinic | undefined;

  admin = $app.module(Admin);

  images: Array<IAttachment> = [];
  tab = 0;
  item: IClinic | null = null;
  reseting = false;
  pwd = '';

  @Watch('value')
  onShow() {
    if (this.value) {
      this.item = $app.clone(this.clinic);
      if (this.item!.background) {
        this.images = [
          {
            id: null,
            companyId: null,
            userId: null,
            state: 'a',
            name: 'background',
            mime: 'image/',
            ext: '',
            created: null,
            info: '',
            thumb: this.item!.background,
            url: '',
            file: null,
            uploaded: 0,
            image: true
          }
        ];
      }
    }
  }

  get visible(): boolean {
    return this.value || false;
  }

  set visible(value: boolean) {
    this.$emit('input', value);
  }

  store() {
    if (this.images && this.images.length > 0) {
      this.item!.background = this.images[0].thumb;
    }
    this.$emit('store', this.item)
  }

  remove() {
    this.$emit('remove', this.item)
  }
}
